// import axiosInstance from "@/core/services/ApiAxios";
import ApiService from "@/core/services/ApiService";
import apiCustomEdit from "@/core/services/ApiCustomEdit";

export const uploadImage = (payload) => {
  return ApiService.post(`/profile/update-image`, payload);
};


export const uploadPostImage = async (payload) => {
  return await apiCustomEdit.post(`/upload/` , payload);
};

